import React, {useCallback, useRef, useState} from "react";
import Layout from "../layouts/de";
import { scrollElementIntoView } from "../components/helper";
import { Col, Container, Row } from "reactstrap";
import woocommerce from "../assets/icons/icons8-woocommerce-color.svg";
import magento from "../assets/icons/icons8-magento-color.svg";
import shopify from "../assets/icons/icons8-shopify-color.svg";
import advantage1 from "../assets/icons/emojis/desktopcomputer.png";
import advantage2 from "../assets/icons/emojis/einkaufswagen.png";
import advantage3 from "../assets/icons/emojis/mann-am-serviceschalter.png";
import slider1 from "../images/screenshot-steele-meals.png";
import slider2 from "../images/screenshot-kickass-by-laura-philipp.png";
import slider3 from "../images/screenshot-my-source-nutrition.png";
import step1 from "../images/pexels-photo-1181715-compressor.jpeg";
import step2 from "../images/pexels-photo-5077047-compressor.jpeg";
import step3 from "../images/pexels-photo-1181244-compressor.jpeg";
import step4 from "../images/pexels-photo-1251855-compressor.jpg";
import header from "../images/istock-510591412-compressor.jpg";
import SectionContact from "../components/sections/sectioncontact";
import Collapsible from 'react-collapsible';
import {throttle} from "lodash";

function Ecommerce(props) {
  const scrollTop = useRef(0);
  if (typeof document !== `undefined`) {
    document.documentElement.classList.remove('nav-open');
  }
  const resizeListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  const scrollListener = useCallback(() => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (document.getElementById('page-slider')) {
      let scroll = document.getElementById('page-slider').getBoundingClientRect();

      if (scroll.top <= window.innerHeight && scroll.top >= -scroll.height) {
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let elementWidth = scroll.width;
        let elementHeight = scroll.height;
        if (st > scrollTop.current){
          scroll = scroll.bottom;
        } else {
          scroll = scroll.bottom;
        }
        let elementWidthTotal = 0;
        if (windowWidth >= 992) {
          elementWidthTotal = (elementWidth - 716) * 3 + 2 * 24;
        } else if (windowWidth >= 576) {
          elementWidthTotal = (elementWidth - 258) * 3 + 2 * 24;
        } else {
          elementWidthTotal = (elementWidth - 80) * 3 + 2 * 16;
        }
        let difference = elementWidthTotal - elementWidth;
        let heightTotal =  windowHeight + elementHeight;
        let percentage = scroll / heightTotal;
        if (percentage >= 0 && percentage <= 1) {
          let reversePercentage = 1 - percentage;
          if (st > scrollTop.current){
            document.getElementById('page-slider').style.transform = 'translateX(-' + (reversePercentage * difference) + 'px)';
          } else {
            document.getElementById('page-slider').style.transform = 'translateX(-' + (difference - (percentage * difference)) + 'px)';
          }
        }
      }
    }
    scrollTop.current = st <= 0 ? 0 : st;
  }, []);
  const loadListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (typeof window !== `undefined` && window.location.hash !== '') {
      if (document.getElementById(window.location.hash.replace('#', '')) !== null) {
        setTimeout(() => {
          scrollElementIntoView(document.getElementById(window.location.hash.replace('#', '')));
        }, 500);
      }
    }
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
    window.addEventListener('resize', throttle(resizeListener, 200));
    window.addEventListener('load', loadListener);
    window.addEventListener('scroll', throttle(scrollListener, 20));
    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('load', loadListener);
    }
  }, []);

  return (
    <Layout classNameChild="ecommerce" location={props.location} title="E-Commerce Agentur aus Karlsruhe | newww.agency" description="Als E-Commerce-Agentur aus Karlsruhe bauen wir deinen individuellen Online-Shop. Experten in Beratung, Design, Entwicklung und Betreuung von Websites & Landingpages." keywords="Online-Shop, Konzeption, UX/UI-Design, Entwicklung, Shopware, Magento, Shopify, WooCommerce, " robots="index, follow, notranslate">
      <div className="section section-dark section-header" id="ecommerce">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="header-subheadline">Individueller Online-Shop</h3>
                <h1 className="header-headline">Als E-Commerce Agentur bringen wir deine Produkte ins Internet.</h1>
                <a className="create-contact dark" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" style={{backgroundImage: `url(${header})`}} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-tripple" id="advantages">
        <Container>
          <h2 className="headline">Dein individueller Online-Shop genau so, wie du es dir vorstellst.</h2>
          <p className="description">Unsere Leistungen umfassen die Konzeption, Gestaltung und Entwicklung von flexiblen, leistungsstarken und nutzerfreundlichen E-Commerce Lösungen, die Kunden begeistern und Umsätze steigern.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage1})`}}/>
                <h3 className="box-title">100% Custom Design</h3>
                <p className="box-description">Zusammen mit dir entwickeln wir deinen individuellen Online-Shop genau so, wie du ihn dir vorstellst und erwecken deine Produkte zum Leben.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage2})`}}/>
                <h3 className="box-title">Maximale Flexibilität</h3>
                <p className="box-description">Wir bauen deinen Online-Shop so, dass du im Anschluss maximaler Flexibilität begegnest und einfach Anpassungen machen kannst.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage3})`}}/>
                <h3 className="box-title">Alles aus einer Hand</h3>
                <p className="box-description">Bei uns erhältst du alle Leistungen aus einer Hand. Wir denken all unsere Projekte ganzheitlich und können alles, was mit Web zu tun hat.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <div className="section section-dark section-slider" id="slider">
        <Container>
          <h2 className="headline">Wir lieben unsere Kunden und arbeiten eng mit diesen zusammen.</h2>
          <p className="description">Bei jedem unserer Projekte setzen wir auf eine enge Zusammenarbeit mit unseren Kunden. Im Mittelpunkt steht dabei immer der spätere Nutzer, den wir ein einmaliges Einkaufserlebnis bieten wollen.</p>
          <div className="slider" id="page-slider">
            <div className="slider-img" style={{backgroundImage: `url(${slider1})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider2})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider3})`}}/>
          </div>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <div className="section section-knowledge" id="knowledge">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="knowledge-subheadline">Wusstest du, dass …</h3>
                <h2 className="knowledge-headline">76% der Deutschen mindestens einmal im Monat online einkaufen.</h2>
                <p className="knowledge-paragraph">
                  Der Online-Handel boomt und nimmt immer mehr an Bedeutung zu. Über Dreiviertel der Deutschen kaufen mindestens einmal im Monat online ein. Experten gehen sogar davon aus, dass der Online-Handel bis 2040 ganze 95% des Gesamtumsatzes ausmacht. Zeit also, sich mit dem Thema auseinanderzusetzen.
                </p>
                <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-dark section-line" id="line">
        <Container>
          <h2 className="headline">Ein Online-Shop-Projekt dauert bei uns nur wenige Wochen bis zum Go-Live.</h2>
          <p className="description">Bei all unseren Projekten setzen wir auf agile und effiziente Prozesse. Ein Online-Shop-Projekt dauert bei uns in der Regel nur wenige Wochen bis zum Go-Live. Unsere Teams arbeiten dabei ohne Reibungsverluste Hand in Hand.</p>
          <Row>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step1})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Screening</h4>
                <p className="box-description">Im ersten Schritt beschäftigen wir uns intensiv mit deinem Vorhaben, planen das Projekt, analysieren deine Wettbewerber und evaluieren die Kundenbedürfnisse.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step2})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">UX/UI Design</h4>
                <p className="box-description">Weiter geht es mit der Gestaltung deines Online-Shops, an der du aktiv teilnimmst und deinen Input gibst. Das Ergebnis ist ein detailliertes Screendesign für alle Endgeräte.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step3})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Entwicklung</h4>
                <p className="box-description">Danach legen unsere Entwickler mit der Umsetzung los und geben Vollgas. Sobald die einzelnen Seiten und Funktionen fertig sind, werden diese intensiv getestet.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step4})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Go-Live</h4>
                <p className="box-description">Sind alle Inhalte und Plugins eingepflegt, kann es mit deinem Shop auch schon losgehen. Selbstverständlich betreuen wir unsere Kunden auch im Anschluss.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <div className="section section-tripple" id="technologies">
        <Container>
          <h2 className="headline">Wir arbeiten mit den besten Shop-Systemen, die es gibt.</h2>
          <p className="description">Ob Magento, Shopify oder Woo-Commerce, wir arbeiten mit den besten Shop-Systemen, die es gibt und richten uns nach unseren Kunden. Unser Ziel ist dabei immer die Entwicklung von Shops, die maximale Flexibilität bieten.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${magento})`}}/>
                <h3 className="box-title">Magento</h3>
                <p className="box-description">Magento gehört seit vielen Jahren zu den führenden Shop-Systemen. Durch seine vielseitigen Möglichkeiten ist es besonders im B2B-Geschäft beliebt.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${shopify})`}}/>
                <h3 className="box-title">Shopify</h3>
                <p className="box-description">Shopify unterstützt über 1.000.000 Unternehmen weltweit und zeichnet sich vor allem durch seine Einfachheit und Flexibilität aus.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${woocommerce})`}}/>
                <h3 className="box-title">WooCommerce</h3>
                <p className="box-description">Mit 15 Millionen Downloads zählt WooCommerce zu einem der populärsten und mistverwendeten Wordpress-Plugins weltweit.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <div className="section section-dark section-faq" id="faq">
        <Container>
          <h2 className="headline">Häufige Fragen (FAQ)</h2>
          <Row>
            <Col lg="12" className="faq-wrapper">
              <ul>
                <li>
                  <Collapsible trigger="Wie lange dauert ein Online-Shop-Projekt?">
                    <p>
                      Die Dauer eines Online-Shop-Projekts hängt von einer Vielzahl unterschiedlicher Faktoren ab. Einer der wesentlichen Rollen spielt hierbei der Umstand, ob auf ein bereits bestehenden Theme zurückgegriffen oder ein individuelles Design umgesetzt wird. In der Regel dauern unsere Online-Shop-Projekte jedoch nur wenige Wochen.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Wie viel kostet ein Online-Shop?">
                    <p>
                      Wir richten uns bei all unseren Projekten nach dem Budget unserer Kunden und machen es uns zur Aufgabe mit den zur Verfügung stehenden Mittel das Maximum rauszuholen. Unser üblicher Stundensatz liegt bei 80,00 € zzgl. MwSt. Die Kosten hängen somit immer von der Komplexität und dem Projektumfang ab.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Wie läuft ein Online-Shop-Projekt ab?">
                    <p>
                      Wir starten jedes unserer Projekte mit einem intensiven Screening, indem wir dein Vorhaben genau unter die Lupe nehmen. Weiter geht es mit dem UX/UI Design. Hier wird das Konzept erarbeitet, das die Entwickler dann eins zu eins umsetzen. Ist der Content eingepflegt und alles getestet, kann es mit dem Produktverkauf losgehen.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Welche Online-Shop-Systeme kommen zum Einsatz?">
                    <p>
                      Wir sind flexibel was unsere eingesetzten Technologien angeht und richten uns hier nach unseren Kunden. Besonders oft zum Einsatz kommt bei uns Shopify, das sich durch seine Einfachheit auszeichnet. Als Shopify-Partner entwickeln wir für unsere Kunden beispielsweise auch eigene Themes und Plugins.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Wo bietet newww.agency seine Leistungen an?">
                    <p>
                      Unser Hauptsitz ist in Karlsruhe. Unsere Kunden sind hingegen weltweit zuhause. Wir betreuen unsere Kunden standortübergreifend und haben Prozesse etabliert, die eine flexible und reibungslose Zusammenarbeit ermöglichen.
                    </p>
                  </Collapsible>
                </li>
              </ul>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <SectionContact lang='de' />
    </Layout>
  );
}

export default Ecommerce;
